import { useEffect, useState } from "react"
import { login, signup } from "../apis/api/auth/api";
import { useNavigate } from "react-router-dom";
import { getAccessLevel, storeInitData } from "../apis/services/init";
import signupStyle from '../style/login.module.css';

const Signup = () => {
    // auto-fill
    const accountObj = JSON.parse(sessionStorage.getItem('accountObj'));
    const [enableButton, setEnableButton] = useState(false);
    const [inputList, setInputList] = useState({
        'signup-input-0': {
            id: 'signup-input-0',
            nameInput: 'email',
            type: 'email',
            labelText: '이메일(ID)',
            placeholder: 'abc@email.com',
            isValid: (accountObj?.email && true) || false,
            value: (accountObj?.email || ''),
        },
        'signup-input-1': {
            id: 'signup-input-1',
            nameInput: 'password',
            type: 'password',
            labelText: '비밀번호',
            placeholder: '8~16자 이내 영문, 숫자, 특수문자 사용 가능',
            isValid: false,
            value: '',
        },
        'signup-input-2': {
            id: 'signup-input-2',
            nameInput: 'passwordConfirm',
            type: 'password',
            labelText: '비밀번호 확인',
            placeholder: '위 비밀번호와 동일하게 입력해 주세요',
            isValid: false,
            value: '',
        },
        'signup-input-3': {
            id: 'signup-input-3',
            nameInput: 'mallUrl',
            type: 'text',
            labelText: '쇼핑몰 주소',
            placeholder: 'www.gentooai.com',
            isValid: (accountObj?.mallUrl && true) || false,
            value: (accountObj?.mallUrl || ''),
        },
        'signup-input-4': {
            id: 'signup-input-4',
            nameInput: 'partnerCompanyName',
            type: 'text',
            labelText: '회사명',
            placeholder: '회사명을 입력해 주세요',
            isValid: (accountObj?.companyName && true) || false,
            value: (accountObj?.companyName || ''),
        },
        'signup-input-5': {
            id: 'signup-input-5',
            nameInput: 'manager',
            type: 'text',
            labelText: '담당자명',
            placeholder: '담당자명을 입력해 주세요',
            isValid: (accountObj?.presidentName && true) || false,
            value: (accountObj?.presidentName || ''),
        },
    });

    const updateInputList = (id, key, val) => {
        setInputList(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [key]: val,
            }
        }));
    }

    const validLoop = () => {
        for (const [key, value] of Object.entries(inputList)) {
            if (!inputList[key].isValid) return false;
        }
        return true;
    }

    useEffect(() => {
        setEnableButton(validLoop());
    }, [inputList])
    
    return (
        <div style={{width: '100%', height: 'fit-content', background: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', padding: '56px'}}>
            <div style={{width: '472px', height: '891px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{width: '360px', minHeight: '152px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <img src="/img/units/gentoo-symbol.png" width={56} height={56} />
                    <div className="spacing-24" />
                    <p className="h4 fc-gray-700">회원 정보를 입력해주세요</p>
                    <div className="spacing-8" />
                    <p className="h7 fc-gray-600">회원 가입을 완료하면 쇼핑몰 데이터를 학습한</p>
                    <p className="h7 fc-gray-600">인공지능 점원 젠투가 생성됩니다</p>
                </div>
                <div className="spacing-24" />
                <div style={{width: '360px', height: '640px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px'}}>
                    {
                        Object.keys(inputList).map((key, idx) => {
                            return (
                                <div key={idx}>
                                    <InputComp idx={idx} type={inputList[key].type} nameInput={inputList[key].nameInput} id={inputList[key].id} labelText={inputList[key].labelText} placeholder={inputList[key].placeholder} value={inputList[key].value} updateInputList={updateInputList} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="spacing-24" />
                <SignupButton isActive={enableButton} signup={signup} inputList={inputList} accountObj={accountObj} />
            </div>
        </div>
    )
}

const InputComp = (props) => {
    const {idx, type, nameInput, id, labelText, placeholder, value, updateInputList} = props;
    const [errorState, setErrorState] = useState();

    const getHelpMessage = (helpType) => {
        if (helpType === null) return;
        const helpMessage = getErrorMessages(nameInput);
        if (helpType === 'error') {
            return (
                <div style={{width: '100%', display: 'flex', marginTop: '6px'}}>
                    <img src='/img/units/input-error.png' width={13} height={13} />
                    <div className="spacing-4" />
                    <p className="h13 fc-warning">{helpMessage}</p>
                </div>
            )
        } else if (helpType === 'success') {
            return (
                <div style={{width: '100%', display: 'flex', marginTop: '6px'}}>
                    <img src='/img/units/input-success.png' width={13} height={13} />
                    <div className="spacing-4" />
                    <p className="h13 fc-positive-900">{helpMessage}</p>
                </div>
            )
        } else if (helpType === 'default') {
            return (
                <div style={{width: '100%', display: 'flex', marginTop: '6px'}}>
                    <p className="h13 fc-gray-500">{helpMessage}</p>
                </div>
            )
        } else {
            return;
        }
    }

    const getErrorMessages = (nameInput) => {
        switch (nameInput) {
            case 'email':
                return '올바른 형식의 이메일 주소를 입력해 주세요';
            case 'password':
                return '8~16자 이내 영문, 숫자, 특수문자만 사용 가능합니다';
            case 'passwordConfirm':
                return '비밀번호가 일치하지 않습니다';
            default:
                return '';
        }
    }

    const validateInput = (e) => {
        const value = e.currentTarget.value;
        // const value = 'test@email.com'
        let exp;
        let res;
        switch (nameInput) {
            case 'email':
                exp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                res = new RegExp(exp).test(value);
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            case 'password':
                exp = /^[a-zA-Z0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,16}$/;
                res = new RegExp(exp).test(value);
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            case 'passwordConfirm':
                const password = document.getElementById('signup-input-1').value;
                res = password === value;
                updateInputList(id, 'isValid', res);
                return (res ? '' : 'error');
            default:
                res = value.length > 0;
                updateInputList(id, 'isValid', res);
                return '';
        }
    }

    return (
        <div style={{width: '360px', height: 'fit-content', minHeight: '70px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <label htmlFor={id} className="h11 fc-gray-600" style={{marginBottom: '6px'}}>
                {labelText}
                <span className="h11 fc-warning">*</span>
            </label>
            <input 
                type={type} name={nameInput} id={id} 
                placeholder={placeholder}
                value={value}
                className={errorState === 'error' ? signupStyle.Signup__Input__error : signupStyle.Signup__Input}
                onChange={(e) => {
                    // if (e.currentTarget.value.length < 3) setErrorState('error')
                    // else {setErrorState(null)}
                    const res = validateInput(e);
                    setErrorState(res);
                    updateInputList(id, 'value', e.currentTarget.value);
                }}
            />
            {getHelpMessage(errorState)}
        </div>
    )
}

const SignupButton = (props) => {
    const {isActive, signup, inputList, accountObj} = props;
    const navigate = useNavigate();

    const handleSubmit = () => {
        signup(inputList, accountObj.mallId, accountObj.shopUserId)
            .then(res => {
                if(res.success) {
                    sessionStorage.setItem('ptid', res.partnerId);
                    login(inputList['signup-input-0'].value, inputList['signup-input-1'].value)
                        .then(res => {
                            if (res.partnerId === undefined) {
                                alert('아이디 또는 비밀번호를 다시 확인하세요.');
                            } else {
                                storeInitData(res);
                                navigate('/home');
                            }
                        })
                        .catch(error => alert(error))
                }
            })
    }
    
    if (isActive) {
        return (
            <div style={{width: '360px', height: '51px', minHeight: '51px'}}>
                <button 
                    style={{width: '100%', height: '100%', border: 'none', borderRadius: '10px', background: '#222'}}
                    onClick={() => {handleSubmit()}}
                >
                    <p className="h6 fc-white">가입하기</p>
                </button>
            </div>
        )
    } else {
        return (
            <div style={{width: '360px', height: '51px', minHeight: '51px'}}>
                <button 
                    style={{width: '100%', height: '100%', border: 'none', borderRadius: '10px', background: '#e1e1e1'}}
                    disabled={true}
                    onClick={(e) => {alert('rrr')}}
                >
                    <p className="h7 fc-gray-500">가입하기</p>
                </button>
            </div>
        )
    }
}

export default Signup;
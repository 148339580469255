import styled from 'styled-components';
import { colors } from '../style/colorVar';

const Prep = () => {
    return (
        <Container>
            <FlexWrap>
                <img src='/img/units/prep-img.png' width={250} height={182} />
                <div className='spacing-20' />
                <p className='h3 fc-gray-700'>현재 페이지 준비중입니다</p>
                <div className='spacing-8' />
                <p className='h7 fc-gray-700' style={{width: '250px', textAlign: 'center'}}>보다 나은 서비스를 제공하기 위하여<br />페이지 준비중에 있습니다.<br />빠른 시일 내에 준비하여 찾아뵙겠습니다.</p>
            </FlexWrap>
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    // background-color: ${colors.base};
    border-radius: 10px;
`

const FlexWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Contents = styled.span`
    font-size: 2em;
    font-weight: 800;
    color: ${colors.fontGray};
`

export default Prep
import styled from 'styled-components';
import { colorSet, colors } from '../../style/colorVar';
import { getCardData, getWAU } from '../../apis/api/kpis';
import { getCardDataList, getWAUList } from '../../apis/services/kpis';
import { useEffect, useState } from 'react';
import { ClickRateChart, FunnelChart } from './Charts';
import { TopNav, TopNavDLST } from './TopNav';
import { ChartBox, ChartTitle, Container, FlexContainer, GridContainer, Spacer, TextDeco } from '../../style/Units';
import { getFormattedDate } from '../../apis/api/utils';

const Dashboard = () => {
    // For chart1
    const dashboardUrl = sessionStorage.getItem('durl');

    return (
        <div style={{width: '100%', height: '100%'}}>
            <iframe
                src={dashboardUrl}
                style={{width: '100%', height: '100%', border: 'none'}}
            />
        </div>
    )
};

const KPICard = styled.div`
    width: 310px;
    height: 132px;
    background: ${colorSet.prim50};
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.25);
`


const Board = styled.div`
    background-color: ${colors.base};
    margin: 8px;
    border-radius: 10px;
`

const GridBoard = styled(Board)`
    display: grid;
    grid-template-columns: ${props => props.$gridCol || ""};
    grid-template-rows: ${props => props.$gridRow || ""};
    grid-column-start: ${props => props.$gridColStart || ""};
    grid-column-end: ${props => props.$gridColEnd || ""};
`

const InfoPannel = styled.div`
    width: 100%;
    padding: 48px 40px;
`

const Content = styled.div`
    width: 100%;
    margin-bottom: 48px;

    h1 {
        font-size: 40px;
        font-weight: 900;

        span {
            font-size: 24px;
        }
    }
`

const FunnelTable = styled.table`
    width: calc(100% - 100px);
    height: 80px;
    margin-left: 80px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
`

const SubBoard = styled.div`
    width: 100%;
    // background-color: green;
    // margin: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const KPILabel = styled.p`
    font-size: ${props => props.$fontSize || "0.8rem"};
    font-weight: ${props => props.$fontWeight || 800};
    line-height: 1.5;
`

const InfoIcon = styled.img`
    width: 16px;
    margin: 0 4px;
`

const InfoModal = styled.span`
    position: absolute;
    width: ${props => props.$width || "140px"};
    padding: 8px;
    border-radius: 5px;
    background-color: ${colors.backDark};
    color: ${colors.fontGray};
    font-size: 12px;
    word-break: keep-all;
    z-index: 10;
`

const HeadIcon = styled.div`
    width: 40px;
    height: 40px;
    margin-top: 8px;
    background: url("/img/dashboard/trending_up_FILL0_wght700_GRAD0_opsz48.svg");
`

export default Dashboard
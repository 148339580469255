
export const getAccessLevel = (partnerPlan) => {
    switch (partnerPlan) {
        case 'ENTERPRISE':
            return 4;
        case 'EXPERT':
            return 3;
        case 'ADVANCED':
            return 2;
        case 'PRO':
            return 1;
        default:
            return 0;
    }
}

export const storeInitData = (obj) => {
    const partnerId = sessionStorage.getItem('ptid');
    sessionStorage.setItem('ptid', partnerId || obj.partnerId);
    sessionStorage.setItem('ptt', obj.partnerType);
    sessionStorage.setItem('l', getAccessLevel(obj.partnerPlan));
    sessionStorage.setItem('cbid', obj.chatBotList[0]?.chatBotId || 1);
    sessionStorage.setItem('initializeState', obj.initializeState);
    sessionStorage.setItem('isAnalyticsReady', obj.isAnalyticsReady);
    sessionStorage.setItem('durl', obj.dashboardURL);
    sessionStorage.setItem('at', obj.activatedTime);
}
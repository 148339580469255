import { useEffect, useState } from 'react';
import unitStyle from '../../style/units.module.css';

export const TimerBanner = () => {
    return (
        <div style={{width: '100%', height: 'fit-content', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '208px', height: '140px', background: '#f2f4fa', borderRadius: '10px', padding: '12px 16px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src='/img/units/onboard-trial-badge.png' width={50} height={21} />
                    <div className='spacing-8' />
                    <p className='h8 fc-gray-700'>체험 기간</p>
                </div>
                <div className='spacing-12' />
                <TimeProgress />
                <div className='spacing-12' />
                <button 
                    style={{width: '100%', height: '41px', border: '1px solid #e1e1e1', background: '#fff', borderRadius: '8px'}}
                    onClick={() => window.open('https://whattime.co.kr/waddle/pricing-consult', "_blank")}
                >
                    <p className='h8 fc-gray-800'>유료 전환 신청하기</p>
                </button>
            </div>
        </div>
    )
}

export const TimeProgress = () => {
    const [percentage, setPercentage] = useState();
    const [remainingDay, setRemainingDay] = useState();
    const activatedTime = sessionStorage.getItem('at');

    useEffect(() => {
        const time = new Date(activatedTime);
        const now = new Date();
        const elapsedTime = now - time;
        const totalDuration = 7 * 24 * 60 * 60 * 1000; // 7일을 밀리초로 변환
        const remainingTime = totalDuration - elapsedTime;
        setRemainingDay(remainingTime < 0 ? 0 : Math.floor((remainingTime) / (24 * 60 * 60 * 1000)));

        if (remainingTime <= 0) {
            setPercentage(0);
            sessionStorage.setItem('isExp', true);
        } else {
            const percent = (remainingTime / totalDuration) * 100;
            setPercentage(percent.toFixed(2)); // 소수점 두 자리까지 표시
            sessionStorage.setItem('isExp', false);
        }
    }, [])

    return (
        <>
            <div className={unitStyle.TimeProgress__Container}>
                <div className={unitStyle.TimeProgress__Bar} style={{ width: `${percentage}%` }} />
            </div>
            <div className='spacing-8' />
            <p className='h12 fc-prim-800'>체험 기간이 {remainingDay}일 남았습니다</p>
        </>
    )
}
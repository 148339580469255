
export const rankChanges = (num, isNew) => {
    let type;
    if (isNew) {type = 'new'}
    else if (num === 0) {type = 'hold'}
    else if (num > 0) {type = 'up'}
    else {type = 'down'}

    switch (type) {
        case 'up':
            return (
                <span className="h7 fc-warning">{`▲ ${num}`}</span>
            )
        case 'down':
            return (
                <span className="h7 fc-prim-800">{`▼ ${Math.abs(num)}`}</span>
            )
        case 'new':
            return (
                <span className="h7 fc-prim-800">{'NEW'}</span>
            )
        default:
            return (
                <span className="h7 fc-gray-800">{`-`}</span>
            )
    }
}
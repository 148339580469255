import { useParams } from 'react-router-dom';
import agentStyle from '../../style/agent.module.css';
import unitsStyle from '../../style/units.module.css';
import chatPreviewStyle from '../../style/chatpreview.module.css';
import { TableLabel } from '../Units/Label';
import { useEffect, useState } from 'react';
import { BotList } from './BotList';
import { BotStat } from './BotStat';
import Onboarding from '../Units/Onboarding';

export const ChatPreview = () => {
    const isOnboardingDone = JSON.parse(sessionStorage.getItem('onboard'));
    const [isOnboardingEnable, setIsOnboardingEnable] = useState(isOnboardingDone); // 추후 결제 완료 state에 따라 TF 분기 필요
    if (isOnboardingDone === null) {
        sessionStorage.setItem('onboard', true)
        setIsOnboardingEnable(true)
    }
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const partnerType = sessionStorage.getItem('ptt');
    const initializeState = sessionStorage.getItem('initializeState');
    const isExpired = JSON.parse(sessionStorage.getItem('isExp'));
    // params for chatUrl build-up
    const toggleActiveStyle = chatPreviewStyle.AgentToggle__active;
    const toggleInactiveStyle = chatPreviewStyle.AgentToggle__inactive;
    const [tabStatus, setTabStatus] = useState([toggleActiveStyle, toggleInactiveStyle]);

    const handleClickToggle = (e) => {
        const selectedId = e.target.className;
        if (selectedId.includes('toggle-mobile')) {setTabStatus([toggleActiveStyle, toggleInactiveStyle])}
        else {setTabStatus([toggleInactiveStyle, toggleActiveStyle])}
    }

    // useEffect(() => {
    //     setIsOnboardingEnable(isOnboardingDone)
    // }, [isOnboardingDone])

    const getChatPreview = () => {
        if (isExpired) {
            return (
                <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{
                        width: '390px', 
                        height: '754px', 
                        borderRadius: '20px', 
                        overflow: 'hidden', 
                        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)', 
                        backgroundImage: 'url(/img/chatPreview/preview-block.png)', 
                        backgroundSize: 'contain', 
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <p className='h4 fc-gray-600'>체험 기간이 종료되었습니다</p>
                        <div className='spacing-8' />
                        <p className='h8 fc-gray-600'>‘유료 전환 신청하기’ 버튼을 통해<br />에이전트를 활성화할 수 있습니다</p>
                        <div className='spacing-24' />
                        <button 
                            style={{width: '262px', height: '52px', border: 'none', background: '#e0e6f3', borderRadius: '8px'}}
                            onClick={() => window.open('https://whattime.co.kr/waddle/pricing-consult', "_blank")}
                        >
                            <div style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                <p className='h6 fc-prim-800'>유료 전환 신청하기</p>
                                <div className='spacing-8' />
                                <img src='/img/units/send-prim.png' width={20} height={20} />
                            </div>
                        </button>
                    </div>
                    <div className='spacing-32' />
                </div>
            )
        } else if (tabStatus[0] === toggleActiveStyle) {
            return (
                <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{width: '390px', height: '754px', borderRadius: '20px', overflow: 'hidden', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)'}}>
                        <iframe 
                            style={{width: '100%', height: '100%', border: 'none'}}
                            src={`${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&cbid=${chatbotId}&ch=${false}&i=${initializeState > 1}`} 
                            // src={`http://localhost:3000/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&cbid=${chatbotId}&ch='mobile'`}
                        />
                    </div>
                    <div className='spacing-32' />
                </div>
            )
        } else {
            return (
                <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{width: '100%', height: '100%', borderRadius: '20px', overflow: 'hidden', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)'}}>
                        <iframe 
                            style={{width: '100%', height: '100%', border: 'none'}}
                            src={`${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&cbid=${chatbotId}&ch=${false}&i=${initializeState > 1}`} />
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {
                isOnboardingEnable &&
                <Onboarding setIsOnboardingEnable={setIsOnboardingEnable} />
            }
            <div className={agentStyle.AgentList__Container} style={{height: '100%'}}>
                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{width: '100%', height: 'fit-content', flex: '0 0 43px', display: 'flex', justifyContent: 'center'}}>
                        <div className={chatPreviewStyle.AgentToggle__wrap}>
                            <div 
                                className={`${tabStatus[0]} toggle-mobile`} 
                                onClick={handleClickToggle}
                            >
                                <p className='h6 toggle-mobile'>모바일</p>
                            </div>
                            <div 
                                className={`${tabStatus[1]} toggle-desktop`} 
                                onClick={handleClickToggle}
                            >
                                <p className='h6 toggle-desktop'>데스크탑</p>
                            </div>
                        </div>
                    </div>
                    <div className='spacing-32' />
                    {getChatPreview()}
                    {/* {
                        tabStatus[0] === toggleActiveStyle?
                        <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: '390px', height: '754px', borderRadius: '20px', overflow: 'hidden', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)'}}>
                                <iframe 
                                    style={{width: '100%', height: '100%', border: 'none'}}
                                    src={`${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&cbid=${chatbotId}&ch=${false}&i=${initializeState > 1}`} 
                                    // src={`http://localhost:3000/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&cbid=${chatbotId}&ch='mobile'`}
                                />
                            </div>
                            <div className='spacing-32' />
                        </div> :
                        <div style={{width: '100%', height: '100%', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: '100%', height: '100%', borderRadius: '20px', overflow: 'hidden', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)'}}>
                                <iframe 
                                    style={{width: '100%', height: '100%', border: 'none'}}
                                    src={`${process.env.REACT_APP_CHAT_URL}/chatroute/${encodeURIComponent(partnerType)}?ptid=${partnerId}&cbid=${chatbotId}&ch=${false}&i=${initializeState > 1}`} />
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        </>
    )
}
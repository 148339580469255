
export const getFormattedDate = (date = new Date(), type = 'full') => {
    // type: 'full', 'semi'
    const refDate = new Date(date);

    const year = refDate.getFullYear();
    const month = String(refDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줌
    const day = String(refDate.getDate()).padStart(2, '0');


    const formattedDate = ( type === 'full'? `${year}-${month}-${day}` :`${month}-${day}`);

    return formattedDate
}

export const getRecentMonday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = (dayOfWeek + 6) % 7;
    const lastMonday = new Date(today);
    if (dayOfWeek !== 7) {
        lastMonday.setDate(today.getDate() - diff - 7);
    } else {
        lastMonday.setDate(today.getDate() - diff);
    }
    
    return lastMonday;
}
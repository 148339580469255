import styled from 'styled-components';
import { colorSet, colors } from '../style/colorVar';
import mainStyle from '../style/pages/main.module.css';
import unitStyle from '../style/units.module.css';
import '../style/global.css';
import { TopNav } from '../components/Main/TopNav';
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Login from './Login';
import { TimerBanner } from '../components/Units/TimerBanner';
const Main = () => {
    const navigate = useNavigate();
    const location = window.location.pathname;
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const [isLogin, setIsLogin] = useState(false);
    const [tryLogout, setTryLogout] = useState(false);
    const [selectedList, setSelectedList] = useState(localStorage.getItem('p') || 0);
    const [clientWidth, setClientWidth] = useState(0);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const username = 'demo';
    const partnerId = sessionStorage.getItem('ptid');
    const userAccessLevel = sessionStorage.getItem('l');
    const initializeState = sessionStorage.getItem('initializeState');

    const routes = {
        top: [
            {
                id: 0,
                path: '/home',
                name: '젠투 에이전트',
                imgSrc: '/img/main/main-gentoo-agent.png',
                accessLevel: 0,
                // child: [
                //     {
                //         id: 0,
                //         path: `/agent/create/${encodeURIComponent(partnerId)}`,
                //         name: '젠투 에이전트 생성',
                //     },
                //     {
                //         id: 1,
                //         path: `/agent/list/${encodeURIComponent(partnerId)}`,
                //         name: '젠투 에이전트 리스트',
                //     }
                // ]
            },
            {
                id: 1,
                path: `/home/agent/custom/${encodeURIComponent(partnerId)}`,
                name: '인터페이스',
                imgSrc: '/img/main/main-insight.png',
                accessLevel: 0,
            },
            {
                id: 2,
                path: '/home/dashboard',
                name: '대시보드',
                imgSrc: '/img/main/main-dashboard.png',
                accessLevel: 0,
            },
            {
                id: 3,
                path: '/home/analytics',
                name: '분석',
                imgSrc: '/img/main/main-analytics.png',
                accessLevel: 0,
                // child: [
                //     {
                //         id: 0,
                //         path: `/analytics`,
                //         name: '젠투 에이전트 분석',
                //     }
                // ]
            },
            {
                id: 10,
                path: `/home/messages/segment/${encodeURIComponent(partnerId)}`,
                name: '세그멘테이션',
                imgSrc: '/img/main/main-messages.png',
                accessLevel: 4,
                child: [
                    {
                        id: 0,
                        path: `/home/messages/segment/${encodeURIComponent(partnerId)}`,
                        name: '세그먼트 리스트',
                    }
                ]
            },
        ],
        bottom: [
            {
                id: 4,
                path: '/home/usage',
                name: '요금',
                imgSrc: '/img/main/main-plans.png',
                accessLevel: 0,
            },
            {
                id: 5,
                path: '',
                name: '로그아웃',
                imgSrc: '/img/main/main-logout.png',
                accessLevel: 0,
            }
        ]
    };

    const [showAgentMenus, setShowAgentMenus] = useState(false);

    useEffect(() => {
        setClientWidth(document.getElementById('main-layout')?.clientWidth);
        if (partnerId) setIsLogin(true);
    }, []);

    const handleNavigate = (path) => {
        navigate(path);
    }

    const handleToggleSubMenu = (id) => {
        setActiveSubMenu(activeSubMenu === id ? null : id);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        const target = e.currentTarget.id;

        if (target === 'menu6') {
            sessionStorage.clear();
            navigate('/login')
        } 
    }

    return (
        <>
            <div className={mainStyle.Main__Background}>
                {
                    !isLogin ?
                        navigate('/login') :
                        <div className={mainStyle.Main__Wrap}>
                            <div className={mainStyle.Main__SideBar}>
                                <div className={mainStyle.Main__SideBar__TopMenuBox}>
                                    <div className={mainStyle.Main__SideBar__LogoBox}>
                                        <img src='/img/main/main-logo.png' width={145} />
                                    </div>
                                    <div className='spacing-16' />
                                    <div>
                                        {routes.top.map((route, idx) => (
                                            // accesslevel local test
                                            (route.accessLevel <= Number(userAccessLevel) &&
                                                <div className={mainStyle.Main__List} key={idx}>
                                                    <div className={unitStyle.Flex__HLeft}>
                                                        <input
                                                            className={mainStyle.Main__List__MenuInput}
                                                            id={`menu${route.id + 1}`}
                                                            type='radio'
                                                            name='menu'
                                                            onClick={() => {
                                                                handleNavigate(route.path);
                                                                if (route.child) {
                                                                    handleToggleSubMenu(route.id)
                                                                }
                                                            }}
                                                            checked={!route.child && location === route.path}
                                                        />
                                                        <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${route.id + 1}`}>
                                                            <img src={route.imgSrc} width={24} />
                                                            <div className='spacing-16' />
                                                            <p className='h6 fc-gray-600'>{route.name}</p>
                                                            {
                                                                route.child &&
                                                                <>
                                                                    <div className='spacing-16' />
                                                                    <img src='/img/main/main-downarrow.png' width={24} height={24} />
                                                                </>
                                                            }
                                                        </label>
                                                    </div>
                                                    {route.child && activeSubMenu === route.id && (
                                                        <div className={mainStyle.Main__SubMenu}>
                                                            {route.child.map((subRoute, subIdx) => (
                                                                <div className={mainStyle.Main__List} key={subIdx}>
                                                                    <input
                                                                        className={mainStyle.Main__List__MenuInput}
                                                                        id={`submenu${subRoute.id}`}
                                                                        type='radio'
                                                                        name='submenu'
                                                                        onClick={() => handleNavigate(subRoute.path)}
                                                                        checked={location === subRoute.path}
                                                                    />
                                                                    <label className={`${mainStyle.Main__List__SubLabel__Content} pointer`} htmlFor={`submenu${subRoute.id}`}>
                                                                        <div className='spacing-16' />
                                                                        <p className='h7 fc-gray-600'>{subRoute.name}</p>
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    {
                                        (Number(userAccessLevel) === 0 && Number(initializeState) === 2) &&
                                        <TimerBanner />
                                    }
                                    <div className='spacing-24' />
                                    <div className={mainStyle.Main__SideBar__BottomMenuBox}>
                                        <div className={mainStyle.Main__List}>
                                            <div className={unitStyle.Flex__HLeft}>
                                                <input
                                                    className={mainStyle.Main__List__MenuInput}
                                                    id={`menu${routes.bottom[0].id + 1}`}
                                                    type='radio'
                                                    name='menu'
                                                    onClick={() => {
                                                        handleNavigate(routes.bottom[0].path);
                                                        if (routes.bottom[0].child) {
                                                            handleToggleSubMenu(routes.bottom[0].id)
                                                        }
                                                    }}
                                                    checked={!routes.bottom[0].child && location === routes.bottom[0].path}
                                                />
                                                <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${routes.bottom[0].id + 1}`}>
                                                    <img src={routes.bottom[0].imgSrc} width={24} />
                                                    <div className='spacing-16' />
                                                    <p className='h6 fc-gray-600'>{routes.bottom[0].name}</p>
                                                    {
                                                        routes.bottom[0].child &&
                                                        <>
                                                            <div className='spacing-16' />
                                                            <img src='/img/main/main-downarrow.png' width={24} height={24} />
                                                        </>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className={mainStyle.Main__List}>
                                            <div className={unitStyle.Flex__HLeft}>
                                                <input className={mainStyle.Main__List__MenuInput} id={`menu${routes.bottom[1].id + 1}`} type='radio' name='menu' onClick={handleLogout} />
                                                <label className={`${mainStyle.Main__List__MenuLabel} pointer`} htmlFor={`menu${routes.bottom[1].id + 1}`}>
                                                    <img src={routes.bottom[1].imgSrc} width={24} />
                                                    <div className='spacing-16' />
                                                    <p className='h6 fc-gray-600'>{routes.bottom[1].name}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', position: 'relative' }}>
                                <TopNav />
                                <OutletWrap>
                                    <Outlet style={{ width: 'calc(100% - 272px)', overflowY: 'scroll' }} />
                                </OutletWrap>
                            </div>
                        </div>
                }
            </div>
        </>
    )
};


const OutletWrap = styled.div`
    width: calc(100% - 240px);
    // height: calc(100vh - 60px - 16px);
    height: calc(100% - 70px);
    padding: 32px;
    overflow-y: scroll;
    position: relative;
    top: 0;
    left: 240px;
`

const LogoutModal = styled.div`
    position: absolute;
    top: 40%;
    left: 40%;
    width: 300px;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 3px ${colors.fontGray};
    z-index: 10;

    span {
        position: relative;
        top: 5%;
        left: 90%;
        cursor: pointer;
    }

    p {
        text-align: center;
        position: relative;
        top: 16%;
    }

    button {
        position: relative;
        top: 22%;
        left: 17%;
        width: 30%;
        margin: 4px;
        background-color: ${colors.backDark};
        border: none;
        padding: 4px 8px;
        border-radius: 5px;
        font-size: 12px;
    }

`


export default Main
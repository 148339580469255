import { useEffect, useState } from 'react';
import agentStyle from '../../style/agent.module.css';
import { CarouselDirInput, ColorInput, ExceptKeyword, GreetingInput, LogoInput, NameInput, RecommendSizeInput } from '../Units/CustomSet';
import { FileDownload, FileUpload } from "../Units/FileTransfer"
import { Preview } from '../Units/Preview';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchChatbotList, updateChatbotList } from '../../apis/services/create/api';
import { chatBotInitSettings } from '../../data/unitsData';

export const AgentCreate = () => {
    const partnerId = useParams().partnerId;
    localStorage.setItem('user', partnerId);

    return (
        <div className={agentStyle.AgentCreate__Container}>
            <div className={agentStyle.AgentCreate__Wrap}>
                <p className='h2 fc-gray-800'>젠투 에이전트 생성</p>
                <div className='spacing-24' />
                <p className='h7 fc-gray-800'>
                    젠투 에이전트 생성을 위해 템플릿을 다운받고,
                    <br/>데이터를 입력한 뒤 파일을 업로드 해주세요.
                </p>
                <div className='spacing-32' />
                <div className={agentStyle.AgentCreate__Top}>
                    <FileDownload />
                </div>
                <div className='spacing-16' />
                <div className={agentStyle.AgentCreate__Bottom}>
                    <FileUpload acceptable='.xlsx' partnerId={partnerId} />
                </div>
            </div>
        </div>
    )
}

export const AgentCustom = () => {
    const partnerId = useParams().partnerId;
    const userAccessLevel = sessionStorage.getItem('l');
    let example = localStorage.getItem('example');
    example = JSON.parse(example);

    const navigate = useNavigate();
    const [carouselVisible, setCarouselVisible] = useState(false);
    const [chatbotId, setChatbotId] = useState();
    const [initSettings, setInitSettings] = useState();
    // const [initSettings, setInitSettings] = useState({
    //     logoInput: '/img/units/gentoo-symbol.png',
    //     nameInput: '젠투',
    //     colorInput: [
    //         {
    //             hex: '#154cca',
    //             rgb: {
    //                 r: 21,
    //                 g: 76,
    //                 b: 202,
    //                 a: 1,
    //             },
    //             red: 21,
    //             green: 76,
    //             blue: 202,
    //             alpha: 1,
    //         },
    //         {
    //             hex: '#bbc8e5',
    //             rgb: {
    //                 r: 187,
    //                 g: 200,
    //                 b: 229,
    //                 a: 1,
    //             },
    //             red: 187,
    //             green: 200,
    //             blue: 229,
    //             alpha: 1,
    //         },
    //         {
    //             hex: '#e0e6f3',
    //             rgb: {
    //                 r: 224,
    //                 g: 230,
    //                 b: 243,
    //                 a: 1,
    //             },
    //             red: 224,
    //             green: 230,
    //             blue: 243,
    //             alpha: 1,
    //         },
    //     ],
    //     greetingInput: '안녕하세요 🧐 어떤 상품을 찾아드릴까요? 젠투가 추천해드릴게요.',
    //     recommendSizeInput: 'multi',
    //     carouselDirInput: 'carousel-vertical',
    //     exceptKeyword: [],
    //     example: example?.length > 0 ? example : ['','',''],
    // });

    const updateSetting = (key, newValue) => {
        setInitSettings((prev) => ({
            ...prev,
            [key]: newValue,
        }));
    }
    
    const sendQuery = () => {
        updateChatbotList(chatbotId, initSettings);
        const i = initSettings;
        const queryURL = `${process.env.REACT_APP_CHAT_URL}/customset?partnerId=${partnerId}&logo=${i.logoInput}&name=${i.nameInput}&hex0=${encodeURIComponent(i.colorInput[0].hex)}&r0=${i.colorInput[0].red}&g0=${i.colorInput[0].green}&b0=${i.colorInput[0].blue}&a0=${i.colorInput[0].alpha}&hex1=${encodeURIComponent(i.colorInput[1].hex)}&r1=${i.colorInput[1].red}&g1=${i.colorInput[1].green}&b1=${i.colorInput[1].blue}&a1=${i.colorInput[1].alpha}&hex2=${encodeURIComponent(i.colorInput[2].hex)}&r2=${i.colorInput[2].red}&g2=${i.colorInput[2].green}&b2=${i.colorInput[2].blue}&a2=${i.colorInput[2].alpha}&greet=${i.greetingInput}&rs=${i.recommendSizeInput}&cd=${i.carouselDirInput}&ex0=${i.example[0]}&ex1=${i.example[1]}&ex2=${i.example[2]}`
        // window.location.href = queryURL;
        // window.open(queryURL);
        navigate(`/agent/chatpre/${partnerId}`);
        // navigate(`/agent/list/${encodeURIComponent(email)}`)
        //TODO: chatbot query params parsing
    }
    useEffect(() => {
        fetchChatbotList(partnerId)
            .then(res => {
                if (!res) {
                    res = chatBotInitSettings;
                    setChatbotId(res.chatbotId);
                    updateSetting('logoInput', res.profileImg);
                    updateSetting('nameInput', res.name);
                    updateSetting('colorInput', res.colorCode);
                    updateSetting('greetingInput', res.greetingMessage);
                    updateSetting('recommendSizeInput', res.recommendSize);
                    updateSetting('carouselDirInput', res.imageRatio);
                    updateSetting('exceptKeyword', res.exceptKeyword);
                    updateSetting('example', res.examples);
                } else {
                    setChatbotId(res.chatbotId);
                    updateSetting('logoInput', res.profileImg);
                    updateSetting('nameInput', res.name);
                    updateSetting('colorInput', JSON.parse(res.colorCode));
                    updateSetting('greetingInput', res.greetingMessage);
                    updateSetting('recommendSizeInput', res.recommendSize);
                    updateSetting('carouselDirInput', res.imageRatio);
                    updateSetting('exceptKeyword', JSON.parse(res.exceptKeyword));
                    updateSetting('example', JSON.parse(res.examples));
                }
            })
    }, [])

    return (
        <div className={agentStyle.AgentCustom__Container}>
            <p className='h4 fc-gray-700'>인터페이스</p>
            <div className='spacing-32' />
            <div className={agentStyle.AgentCustom__Wrap}>
                <div className={agentStyle.AgentCustom__Left}>
                    <Preview initSettings={initSettings} carouselVisible={carouselVisible} />
                </div>
                <div className={agentStyle.AgentCustom__Right}>
                    <LogoInput initLogo={initSettings?.logoInput} updateSetting={updateSetting} />
                    <div className='spacing-32' />
                    <NameInput initName={initSettings?.nameInput} updateSetting={updateSetting} />
                    <div className='spacing-32' />
                    <ColorInput initColor={initSettings?.colorInput} updateSetting={updateSetting} setCarouselVisible={setCarouselVisible}/>
                    <div className='spacing-32' />
                    <GreetingInput initGreeting={initSettings?.greetingInput} updateSetting={updateSetting}/>
                    <div className='spacing-32' />
                    <RecommendSizeInput initRecommendSize={initSettings?.recommendSizeInput} updateSetting={updateSetting} setCarouselVisible={setCarouselVisible}/>
                    <div className='spacing-32' />
                    <CarouselDirInput 
                        initCarouselDirInput={initSettings?.carouselDirInput} updateSetting={updateSetting}
                    />
                    <div className='spacing-32' />
                    <hr className={agentStyle.AgentCustom__Hr} />
                    {/* <div className='spacing-32' />
                    <ExceptKeyword initKeywords={initSettings.exceptKeyword} updateSetting={updateSetting} /> */}
                    <div className='spacing-48' />
                    <div className={agentStyle.AgentCustom__ButtonWrap}>
                        <button 
                            className={Number(userAccessLevel) === 0 ? agentStyle.AgentCustom__Button__Inactive : agentStyle.AgentCustom__Button} 
                            onClick={() => sendQuery()}
                            disabled={Number(userAccessLevel) === 0}
                        >
                            <p className='h4 fc-white'>배포</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const keyword10Data = {
    head: [
        '순위',
        '예시문구',
        '클릭수',
        '최다 추천 상품',
        '최다 클릭 상품'
    ],
    body: [
        {
            rank: 1,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 2,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 387,
            exposureMost: '코트니벤헴 나파벨리 까베르네소비뇽',
            clickMost: '샹파뉴 샤를르 드 까자노브 브뤼',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 3,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 355,
            exposureMost: '버팔로 트레이스',
            clickMost: '버팔로 트레이스',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 4,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 323,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 5,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 6,
            keyword: '위스키',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 7,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 8,
            keyword: '위스키',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 9,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
        {
            rank: 10,
            keyword: '고기와 어울리는 레드와인',
            clickNum: 409,
            exposureMost: '에반 윌리엄스 블랙',
            clickMost: '조니워커 블랙 레이블',
            detail: `(한줄 설명) 사용자 요청 409건 중 222건에서 '국가명'과 함께 위스키를 찾았습니다.`,
        },
    ]
}

export const initProductCardData = [
    {
        title: '추천 상품 순위',
        head: [
            '순위',
            '상품명',
        ],
        body: [
            {
                rank: 1,
                name: '에반 윌리엄스 블랙',
                productUrl: 'https://dailyshot.co/m/item/10730',
            },
        ]
    },
    {
        title: '클릭 상품 순위',
        head: [
            '순위',
            '상품명',
        ],
        body: [
            {
                rank: 1,
                name: '에반 윌리엄스 블랙',
                productUrl: 'https://dailyshot.co/m/item/10730',
            },
        ]
    },
]

export const initKeywordCardData = [
    {
        title: '함께 언급된 키워드',
        head: [
            '순위',
            '상품명',
        ],
        body: [
            {
                rank: 1,
                keyword: '에반 윌리엄스 블랙',
            },
        ]
    }
]

export const initKeywordTrendData = [
    {
      "name": "02-15",
      "노출량": 3900,
      "amt": 2400
    },
    {
      "name": "02-16",
      "노출량": 3000,
      "amt": 2210
    },
    {
      "name": "02-17",
      "노출량": 2000,
      "amt": 2290
    },
    {
      "name": "02-18",
      "노출량": 2780,
      "amt": 2000
    },
    {
      "name": "02-19",
      "노출량": 1890,
      "amt": 2181
    },
    {
      "name": "02-20",
      "노출량": 2390,
      "amt": 2500
    },
    {
      "name": "02-21",
      "노출량": 3490,
      "amt": 2100
    }
]

export const bookmarkList = {
    head: [
        " ",
        "상품명",
        "노출수",
        "클릭수",
    ],
    body: [
        {
            rank: 1,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '주종',
            mentioned: 150,
            changes: 1,
        },
        {
            rank: 2,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '맛',
            mentioned: 150,
            changes: 0,
        },
        {
            rank: 3,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '상황',
            mentioned: 150,
            changes: -1,
        },
        {
            rank: 4,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '엔티티스',
            mentioned: 150,
            changes: 0,
        },
        {
            rank: 5,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '엔티티스',
            mentioned: 150,
            changes: 0,
        },
        {
            rank: 6,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '엔티티스',
            mentioned: 150,
            changes: 2,
        },
        {
            rank: 7,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '엔티티스',
            mentioned: 150,
            changes: -2,
        },
        {
            rank: 8,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '엔티티스',
            mentioned: 150,
            changes: 0,
        },
        {
            rank: 9,
            iconURL: "/img/analytics/bookmark-fill.png",
            product: '엔티티스',
            mentioned: 150,
            changes: 0,
        },
    ]
}

export const relativeKeywordList = {
    head: [
        "순위",
        "상품명",
        "언급수",
        "클릭수",
    ],
    body: []
}
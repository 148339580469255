import { useState } from 'react';
import { SampleContents } from '../../data/unitsData';
import previewStyle from '../../style/units.module.css';
import { Carousel } from './Carousel';

export const Preview = (props) => {
    const {initSettings, carouselVisible} = props;
    const [isHovered, setIsHovered] = useState([false, false, false]);

    const handleMouseOver = (idx) => {
        setIsHovered((prev) => {
            let newData = [...prev];
            newData[idx] = true;

            return newData
        });
    }

    const handleMouseLeave = () => {
        setIsHovered([false, false, false]);
    }

    return (
        <div className={previewStyle.Preview__Container}>
            <div className={previewStyle.Preview__Wrap}>
                <div className={previewStyle.Preview__Header} />
                <div className={previewStyle.Preview__Contents}>
                    <div className={previewStyle.Preview__Greeting}>
                        <img src={initSettings?.logoInput} width={32} height={32}/>
                        <div className='spacing-8' />
                        <div>
                            <p className='h13 fc-gray-500'>{initSettings?.nameInput}</p>
                            <div className='spacing-4' />
                            <div className={previewStyle.Preview__Bubble__bot}>
                                <p className='h7 fc-gray-800'>{initSettings?.greetingInput}</p>
                            </div>
                        </div>
                    </div>
                    <div className='spacing-16' />
                    {
                        carouselVisible ?
                        <Carousel size={initSettings?.recommendSizeInput} dir={initSettings?.carouselDirInput} /> :
                        <div className={previewStyle.Preview__ExampleWrap}>
                            {
                                initSettings?.example?.map((exam, idx) => {
                                    return (
                                        <div 
                                            key={idx} className={previewStyle.Preview__Bubble__user}
                                            style={{background: isHovered[idx] ? 
                                                `rgba(${initSettings?.colorInput[1].red}, ${initSettings?.colorInput[1].green}, ${initSettings?.colorInput[1].blue}, ${initSettings?.colorInput[1].alpha})` :
                                                `rgba(${initSettings?.colorInput[2].red}, ${initSettings?.colorInput[2].green}, ${initSettings?.colorInput[2].blue}, ${initSettings?.colorInput[2].alpha})`}}
                                            onMouseEnter={() => handleMouseOver(idx)}
                                            onMouseLeave={() => handleMouseLeave()}
                                        >
                                            <p 
                                                className='h7 fc-prim-800' 
                                                style={{color: `rgba(${initSettings?.colorInput[0].red}, ${initSettings?.colorInput[0].green}, ${initSettings?.colorInput[0].blue}, ${initSettings?.colorInput[0].alpha})`}}
                                            >
                                                {exam}
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
                <div className={previewStyle.Preview__Bottom}>
                    <div className={previewStyle.Preview__InputWrap}>
                        <img src='/img/units/preview-reset.png' width={36} height={36} />
                        <div className='spacing-8' />
                        <div className={previewStyle.Preview__Input}>
                            <p className='h7 fc-gray-500'>{initSettings?.nameInput}에게 무엇이든 물어보세요</p>
                            <img src='/img/units/preview-send.png' width={24} height={24}/>
                        </div>
                    </div>
                    <div className='spacing-8' />
                    <p className='h13 fc-gray-400'>powered by Waddle</p>
                </div>
            </div>
        </div>
    )
}
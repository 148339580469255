
export const InfoMessage = (props) => {
    return (
        <div style={{width: 'fit-content', height: 'fit-content', padding: '10px 16px', background: '#efefef', borderRadius: '8px'}}>
            <div style={{display: 'flex'}}>
                <img src="/img/units/info.png" width={16} height={16} />
                <div className="spacing-4" />
                <p className="h10 fc-gray-600">{props.text}</p>
            </div>
        </div>
    )
}
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { getAccountInfo } from "../apis/api/auth/api";
import { storeInitData } from "../apis/services/init";

const Transaction = () => {
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);

    const accountObj = {
        provider: searchParams.get('provider'),
        mallId: searchParams.get('mallId'),
        shopUserId: searchParams.get('userId'),
        isPartner: JSON.parse(searchParams.get('isPartner')),
        email: searchParams.get('email'),
        shopName: searchParams.get('shopName'),
        companyName: searchParams.get('companyName'),
        presidentName: searchParams.get('presidentName'),
        phone: searchParams.get('phone'),
        mallUrl: searchParams.get('mallUrl'),
        baseDomain: searchParams.get('baseDomain'),
    }

    sessionStorage.setItem('accountObj', JSON.stringify(accountObj));
    const partnerId = searchParams.get('partnerId');

    const navigate = useNavigate();

    useEffect(() => {
        if (partnerId) {
            sessionStorage.setItem('ptid', partnerId);
            getAccountInfo(partnerId)
                .then(res => storeInitData(res))
                .then(navigate('/home'));
        } else if (accountObj.mallId === null) {
            navigate('/login');
        } else if (accountObj.isPartner) {
            navigate('/login');
            // TODO: 자동 로그인 추가
        } else if (!accountObj.isPartner) {
            navigate('/signup');
        }
    }, [])
}

export default Transaction;